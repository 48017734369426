@font-face {
  font-family: Roboto;
  src: url('./font/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url('./font/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('./font/Roboto-Regular.ttf');
  font-weight: 300;
}

/* Default CSS */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body{
  font-weight: 500;
  overflow-x: hidden;
  position: relative;
  background-color: #1E1E2C !important;
  color: #fff !important;
  font-family: 'Roboto', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 10px;
  padding: 0;  
  line-height: normal;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/********navbar css*********/
nav.navbar{
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled{
  padding: 0px 0;
  background-color: #1E1E2C;
}

nav.navbar a.navbar-brand{
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link{
  font-weight: 700;
  color: #fff !important;
  letter-spacing: 1px;
  padding: 50px;
  font-size: 30px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active{
  opacity: 1;
}

span.navbar-text{
  display: flex;
  align-items: center;
}

.social-icon{
  display: inline-block;
  margin-left: 14px;
}

.social-icon a{
  width: 80px;
  height: 80px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before{
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before{
  transform: scale(1);
}

.social-icon a img{
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img{
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

navbar-text button{
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.nav-text button span {
  z-index: 1;
}

.nav-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #1E1E2C;
}

.navbar-text button:hover::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon{
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar.navbar-toggler-icon:focus{
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon::after,
nav.navbar .navbar-toggler-icon::before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon::after{
  top:8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after{
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before{
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon{
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 10px;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  margin-top: 120px;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 30px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  margin-top: 40px;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  margin-top: 50px;
  padding: 50px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 100%;
  justify-content: center;
}

/** .banner button {
  color: white;
  background-color: transparent;
  font-weight: 700;
  font-size: 30px;
  margin-left: 240px;
  margin-top: 90px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
} **/

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Contact ************/
.contact {
  background: linear-gradient(90.21deg, #8612AD -5.91%, #318CD3 111.58%);
  padding: 300px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}


/************ Footer ************/
.footer {
  padding: 100px 0 50px 0;
}


.logo-foot { 
  margin-right: 520px;
  margin-bottom: 80px;
  align-items: flex-end;
} 

.footer p {
  margin-top: 20px;
}

.footer .social-icon {
  margin-top: 10px;
}




